.password-guess-input {
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding: 8px;
}

.digit-guess-input {
    position: relative;
    font-family: monospace;
    text-align: center;
    font-size: 64px;
    width: 32px;
    height: 80px;
    line-height: 64px;
    border-bottom: 6px solid rgba(0, 0, 0, 0.4);
    padding: 8px 16px 0 16px;
    margin-right: 16px;
}

.digit-guess-input:last-child {
    margin-right: 0;
}

.digit-guess-input.isFocused {
    background-color: #f0f0f0;
}

.digit-guess-input .hint {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
}

.playerScore {
    display: flex;
}

.playerScore .playerName {
    flex: 1;
    min-width: 160px;
}

.playerScore.activeTurn .playerName {
    font-weight: 700;
    font-style: italic;
}

.playerScore .scoreValue {
    font-weight: 700;
}